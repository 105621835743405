import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"

const EditLoan = ({ isAdd, close, complete, editData }) => {
  const [state, setState] = useState({
    isLoading: false,
    loan_name: "",
    min_amount: "",
    max_amount: "",
    min_period: "",
    max_period: "",
    period_type: "Months",
    installment_type: "Interest + Principle",
    rate_of_intrest: "",
    penalty_intrest: "",
    processing_fee: "",
    tax_amount: "",
    remark: "",
    isActive: true,
  })
  const {
    loan_name,
    min_amount,
    max_amount,
    min_period,
    max_period,
    period_type,
    installment_type,
    rate_of_intrest,
    penalty_intrest,
    processing_fee,
    tax_amount,
    remark,
    isActive,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleAdd = search => {
    __putApiData(`/u_loan_master/${state?._id}`, {
      ...state,
    })
      .then(res => {
        window.alert(res.response.response_message)
        complete()
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        window.alert("failed")
        return updateState({
          isLoading: false,
        })
      })
  }
  useEffect(() => {
    updateState({ ...editData })
  }, [editData])

  return <React.Fragment>{addPlan()}</React.Fragment>

  function addPlan(params) {
    return (
      <Modal isOpen={isAdd} centered size="xl">
        <ModalHeader toggle={close}></ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <Row>
            <Col
              xl={8}
              lg={12}
              md={12}
              sm={12}
              className="border-end border-1 pe-4"
            >
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Enter Loan Name :</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=""
                  className=" border-0 bg-light rounded-0 p-3"
                  value={loan_name}
                  onChange={e => {
                    updateState({ loan_name: e.target.value })
                  }}
                />
              </div>

              <Row>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">
                    Loan Amount : (min amount)
                  </Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder="min"
                    className=" border-0 bg-light rounded-0 p-3"
                    value={min_amount}
                    onChange={e => {
                      updateState({ min_amount: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">(max amount)</Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder="max"
                    className=" border-0 bg-light rounded-0 p-3"
                    value={max_amount}
                    onChange={e => {
                      updateState({ max_amount: e.target.value })
                    }}
                  />
                </div>
              </Row>
              <Row>
                <div className="mb-3 col-md-4">
                  <Label className="form-label">Loan Period : (min)</Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder="min"
                    className=" border-0 bg-light rounded-0 p-3"
                    value={min_period}
                    onChange={e => {
                      updateState({ min_period: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <Label className="form-label">(max)</Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder="max"
                    className=" border-0 bg-light rounded-0 p-3"
                    value={max_period}
                    onChange={e => {
                      updateState({ max_period: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <Label className="form-label">(In)</Label>
                  <select
                    className="form-select border-0 bg-light rounded-0 p-3"
                    value={period_type}
                    onChange={e => {
                      updateState({ period_type: e.target.value })
                    }}
                  >
                    <option value="Months">Months</option>
                    <option value="Weeks">Weeks</option>
                    <option value="Days">Days</option>
                  </select>
                </div>
              </Row>
              <Row>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">
                    Rate Of Interest (Annual){" "}
                  </Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    value={rate_of_intrest}
                    onChange={e => {
                      updateState({ rate_of_intrest: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">
                    Penalty Interest [Per Installment %]
                  </Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    value={penalty_intrest}
                    onChange={e => {
                      updateState({ penalty_intrest: e.target.value })
                    }}
                  />
                </div>
              </Row>
              <Row>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">Processing Fee (Rs.)</Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    value={processing_fee}
                    onChange={e => {
                      updateState({ processing_fee: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">Tax Amount (Rs.)</Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    value={tax_amount}
                    onChange={e => {
                      updateState({ tax_amount: e.target.value })
                    }}
                  />
                </div>
              </Row>
            </Col>
            <Col xl={"4"} md="12">
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Installment Type</Label>
                <select
                  className="form-select border-0 bg-light rounded-0 p-3"
                  value={installment_type}
                  onChange={e => {
                    updateState({ installment_type: e.target.value })
                  }}
                >
                  <option value={"Interest + Principle"}>
                    Interest + Principle
                  </option>
                  <option value={"Interest"}>Interest</option>
                </select>
              </div>
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Status</Label>
                <select
                  className="form-select border-0 bg-light rounded-0 p-3"
                  value={isActive}
                  onChange={e => {
                    updateState({ isActive: e.target.value })
                  }}
                >
                  <option value={true}>Active</option>
                  <option value={false}>De-Acitve</option>
                </select>
              </div>
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Remark</Label>
                <Input
                  name="name"
                  type="textarea"
                  placeholder="  "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={remark}
                  onChange={e => {
                    updateState({ remark: e.target.value })
                  }}
                />
              </div>
              <div className="d-flex gap-2 justify-content-end border-top border-1 pt-3 mt-5">
                <Button
                  onClick={close}
                  className="btn btn-outline-primary bg-white text-primary "
                >
                  Cancel
                </Button>
                <Button
                  className="btn bg-primary border-primary "
                  onClick={__handleAdd}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default withTranslation()(EditLoan)
