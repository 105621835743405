import React, { useState } from "react"
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap"

import { withTranslation } from "react-i18next"
import {
  __deleteApiData,
  __getApiData,
  __postApiData,
  __postApiFormData,
} from "constants/apis"
import { __formatDate } from "constants/constantfun"

const UploadDocument = props => {
  const [state, setState] = useState({
    isLoading: false,
    document_type: "Photo",
    member_id: "",
    new_image: "",
    prv_img: "",
  })
  const { isLoading, document_type, member_id, new_image, prv_img } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleUploadDocument = search => {
    if (!member_id) {
      window.alert("Please enter member ID")
    }
    if (!new_image) {
      window.alert("Please select document")
    }
    const formData = new FormData()
    formData.append("document", new_image)
    formData.append("member_id", member_id)
    formData.append("document_type", document_type)

    __postApiFormData(`/u_document`, formData)
      .then(res => {
        if (res.response.response_code === "200") {
          window.alert("Successfully created")
          return updateState({
            isLoading: false,
          })
        }
        window.alert("Failed")
      })
      .catch(error => {
        console.error(error)
        window.alert("Failed")
        return updateState({
          isLoading: false,
        })
      })
  }
  const handleImageChange = e => {
    const file = e.target.files[0]
    updateState({ new_image: e.target.files[0] })

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        updateState({ prv_img: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  const handleGetDocment = () => {
    if (!member_id) {
      window.alert("Please enter member ID")
    }
    __postApiData(`/g_document`, {
      member_id,
      document_type,
    })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            prv_img: res.data,
            isLoading: false,
          })
        }
        window.alert(res.response.response_message)
        return updateState({
          prv_img: "",
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        window.alert("Failed")
        return updateState({
          prv_img: "",
          isLoading: false,
        })
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className=" rounded-4  py-3">
            <CardBody>
              <Row className="mt-2">
                <Col md={"4"} className="">
                  <Label className="form-label"> Select Document Type </Label>
                  <div className="">
                    <select
                      className="form-select border-0 bg-light rounded-0 p-3"
                      name="document_type"
                      value={document_type}
                      onChange={e => {
                        updateState({ [e.target.name]: e.target.value })
                      }}
                    >
                      <option value="Photo">Photo</option>
                      <option value="Signature">Signature</option>
                      <option value="ID Proof">ID Proof</option>
                      <option value="Add Proof">Add Proof</option>
                    </select>
                  </div>
                </Col>
                <Col md={"4"} className="">
                  <Label className="form-label"> Enter Customer ID : </Label>
                  <Input
                    name="member_id"
                    type="text"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    value={member_id}
                    onChange={e => {
                      updateState({ member_id: e.target.value })
                    }}
                  />
                </Col>
              </Row>
              <div>
                <button
                  onClick={handleGetDocment}
                  className="btn btn-success mt-3"
                >
                  Show Image
                </button>
              </div>

              <img
                src={prv_img}
                alt=""
                width={"250px"}
                height={"250px"}
                className="bg-light mt-3"
              />

              <Row>
                <Col md={"4"} className="mt-3">
                  <Label className="form-label"> Select Image : </Label>
                  <Input
                    name="img"
                    type="file"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    onChange={e => handleImageChange(e)}
                    accept=".png,.jpg"
                  />
                </Col>
              </Row>
              <div>
                <button
                  onClick={__handleUploadDocument}
                  className="btn btn-success mt-3"
                >
                  Save
                </button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(UploadDocument)
