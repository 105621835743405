import React, { useContext, useEffect, useState } from "react"
import { Card, CardBody, Container, Row, Input, Col } from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"
import ListCom from "./ListCom"
import { __getCompanyAccountData } from "constants/commonApis"
import { useNavigate } from "react-router-dom"
const ShareMaster = props => {
  const navigation = useNavigate()
  const [state, setState] = useState({
    history: null,
    isLoading: false,
    member_details: "",
    share_details: "",
    member_id: "001m-000001",
    accountList: [],
  })
  const {
    member_details,
    share_details,
    member_id,
    total_share,
    share_amount,
    remark,
    payment_mode,
    chq_dd_no,
    chq_dd_date,
    company_account,
    customer_id,
    history,
    accountList,
    profit_interest,
  } = state
  const updateState = data => setState(prv => ({ ...prv, ...data }))

  const handleGetMemberDetails = () => {
    updateState({
      isLoading: true,
    })
    __postApiData(`/g_member`, { customer_id: member_id })
      .then(res => {
        if (res.response.response_code === "200") {
          const { customer, customer_share } = res.data
          return updateState({
            isLoading: false,
            member_details:
              "[" + customer?.customer_id + " , " + customer?.name + "]",
            share_details: `Total Available share : ${
              customer_share?.total_share || 0
            } , Total Share Amount : ${
              customer_share?.total_share_amount || 0
            }`,
            history: res?.data?.history || [],
            customer_id: customer?._id,
          })
        }
        window.alert(res.response?.response_message)
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
        })
      })
  }
  const handleCreateNewShare = () => {
    updateState({
      isLoading: true,
    })
    __postApiData(`/c_share`, {
      customer_id: customer_id,
      share_amount: total_share * 100,
      total_share,
      remark,
      payment_mode,
      chq_dd_no,
      chq_dd_date,
      company_account,
      profit_interest,
    })
      .then(res => {
        window.alert(res.response?.response_message)
        if (res.response?.response_code == "200") {
          navigation("/share-report")
          return updateState({
            isLoading: false,
            member_details: "",
            share_details: "",
            member_id: "",
            total_share: "",
            share_amount: "",
            remark: "",
            payment_mode: "",
            chq_dd_no: "",
            chq_dd_date: null,
            company_account: "",
            customer_id: "",
            history: [],
            accountList: [],
            profit_interest: "",
          })
        }
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
        })
      })
  }
  useEffect(() => {
    ;(async () => {
      const newList = await __getCompanyAccountData()
      console.log(newList)
      updateState({ accountList: newList })
    })()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className=" rounded-4">
                <CardBody className="p-5">
                  <Row className="mb-3">
                    <Col md={"3"} className="">
                      <div className="d-flex  align-items-center">
                        Enter Member ID
                      </div>
                    </Col>

                    <Col md={"4"} className="">
                      <div className="">
                        <Input
                          type="text"
                          placeholder="ID no..."
                          className=" border-0 bg-light rounded-0 p-3"
                          name="member_id"
                          value={member_id}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"2"} className="">
                      <button
                        className="btn btn-success "
                        onClick={handleGetMemberDetails}
                      >
                        Show Details
                      </button>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={"3"} className="">
                      <div className="d-flex  align-items-center">
                        Member Details
                      </div>
                    </Col>

                    <Col md={"7"} className="">
                      <div className="">
                        : {member_details}{" "}
                        <span className="text-danger">{share_details}</span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className=" rounded-4 ">
                <CardBody className="p-5">
                  <Row>
                    <Col>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Enter Total No. Of Share
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="total_share"
                              value={total_share}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Payment Mode
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <select
                              className="form-select border-0 bg-light rounded-0 p-3"
                              name="payment_mode"
                              value={payment_mode}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            >
                              <option value="">choose</option>
                              <option value="Cash">Cash</option>
                              <option value="Cheque">Cheque</option>
                              <option value="DD">DD</option>
                              <option value="Saving Account">
                                Saving Account
                              </option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Chq/DD No.
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="chq_dd_no"
                              value={chq_dd_no}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Chq/DD Date
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="date"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="chq_dd_date"
                              value={chq_dd_date}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Select Company A/C
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <select
                              className="form-select border-0 bg-light rounded-0 p-3"
                              name="company_account"
                              value={company_account}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            >
                              <option value="">choose</option>
                              {accountList.map(account => (
                                <option value={account.name} key={account?._id}>
                                  {account.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Total Amount
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            {total_share ? "₹ " + total_share * 100 : ""}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Profit Interest(%)
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="profit_interest"
                              value={profit_interest}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Remarks
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="textarea"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="remark"
                              value={remark}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <button
                        className="btn btn-primary float-end mt-3"
                        onClick={handleCreateNewShare}
                      >
                        Purchase Share
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {history && <ListCom list={history} />}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ShareMaster)
