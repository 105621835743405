import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import AddLoan from "./AddLoan"
import EditLoan from "./EditLoan"

const LoanMaster = props => {
  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isEdit: false,
    edit: null,
    isAdd: false,
  })
  const { leads, isLoading, isAdd, isEdit, edit } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: "LoanName",
        accessor: "loan_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },

      {
        Header: "MinAmount",
        accessor: "min_amount",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "MaxAmount	",
        accessor: "max_amount",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "MinPeriod",
        accessor: "min_period_type",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },

      {
        Header: <div className="text-center"> MaxPeriod </div>,
        accessor: "max_period_type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Anual Interest </div>,
        accessor: "rate_of_intrest",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Penalty</div>,
        accessor: "penalty_intrest",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center"> Processing Fees </div>,
        accessor: "processing_fee",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center"> Tax %</div>,
        accessor: "tax_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center"> Installment Type</div>,
        accessor: "installment_type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: <div className="text-center"> Status</div>,
        accessor: "isActive",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {cellProps.value ? (
                <span className="bg-success badge bg-secondary">Active</span>
              ) : (
                <span className="bg-danger badge bg-secondary">Inactive</span>
              )}
            </div>
          )
        },
      },

      {
        Header: <div className="text-center">Creation Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },
      {
        Header: <div className="text-center">Action</div>,
        accessor: "_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <span
                    className="btn btn-sm btn-soft-success"
                    onClick={() => {
                      updateState({
                        isEdit: true,
                        edit: data,
                      })
                    }}
                  >
                    <i className="mdi mdi-pencil-outline"></i>
                  </span>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )
  const __handleGetUsers = search => {
    __postApiData(`/g_loan_master`, {})
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data.reverse(),
            isLoading: false,
            isAsyncLoading: false,
          })
        }
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ isLoading: true })
    __handleGetUsers("")
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={30}
                classNameHeader="dark"
                isGlobalFilter
                isAddUserList={"Add"}
                handleUserClick={() => {
                  updateState({ isAdd: true })
                }}
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Data Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      {addPlan()}
      {editPlan()}
    </React.Fragment>
  )

  function addPlan(params) {
    return (
      <>
        <AddLoan
          isAdd={isAdd}
          close={() => updateState({ isAdd: false })}
          complete={() => {
            updateState({ isAdd: false })
            __handleGetUsers()
          }}
        />
      </>
    )
  }
  function editPlan(params) {
    return (
      <>
        <EditLoan
          isAdd={isEdit}
          close={() => updateState({ isEdit: false })}
          complete={() => {
            updateState({ isEdit: false })
            __handleGetUsers()
          }}
          editData={edit}
        />
      </>
    )
  }
}

export default withTranslation()(LoanMaster)
