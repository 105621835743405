import React, { useContext, useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { __deleteApiData, __getApiData, __postApiData } from "constants/apis"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import { Link } from "react-router-dom"

const ListCom = ({ list }) => {
  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: <div className="text-center"> Share No.</div>,
        accessor: "share_no",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center"> Total Share</div>,
        accessor: "total_share",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center">Share Amount</div>,
        accessor: "share_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {__formatDate(cellProps.value) || "-"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> Remark</div>,
        accessor: "remark",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <Card className=" rounded-4  py-5">
        <CardBody>
          <TableContainer
            columns={columns}
            data={list}
            customPageSize={30}
            classNameHeader="dark"
            isGlobalFilter
            isNextPrvButtonsEnabled
          />
          {list?.length == 0 && (
            <div className="p-5">
              <div className="text-center ">
                <i className="bx bx-search" style={{ fontSize: "30px" }}></i>
              </div>
              <div className="text-center mt-3">No History Found</div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withTranslation()(ListCom)
