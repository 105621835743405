import React, { useContext, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Input,
  Label,
  Col,
  CardTitle,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"
import { __getOccupationData, __getStateData } from "constants/commonApis"
const EditMember = props => {
  const [state, setState] = useState({
    care_of_type: "S/o",
    name_type: "Mr.",

    stateList: [],
    occupationList: [],
    member_id: "",
  })
  const {
    occupationList,
    stateList,
    name,
    name_type,
    care_of_name,
    care_of_type,
    correspondence_address,
    c_state,
    c_city,
    c_pin,
    permanent_address,
    p_state,
    p_city,
    p_pin,
    dob,
    age,
    gender,
    nationality,
    marital_status,
    mobile_no,
    email,
    is_senior_citizen,
    nominee_name,
    nominee_relation_ship,
    nominee_age,
    nominee_address,
    nominee_state,
    nominee_city,
    nominee_pincode,
    customer_bank_name,
    customer_account_no,
    customer_branch_name,
    customer_ifsc_code,
    customer_pan_no,
    customer_passport_no,
    income_details_occupation,
    income_details_professional_details,
    income_details_monthly_income,
    member_id,
    _id,
  } = state
  const updateState = data => setState(prv => ({ ...prv, ...data }))
  const handleCreateMember = () => {
    updateState({
      isLoading: true,
    })
    __putApiData(`/u_customer_details/${_id}`, {
      name,
      name_type,
      care_of_name,
      care_of_type,
      correspondence_address,
      c_state,
      c_city,
      c_pin,
      permanent_address,
      p_state,
      p_city,
      p_pin,
      dob,
      age,
      gender,
      nationality,
      marital_status,
      mobile_no,
      email,
      is_senior_citizen,
      nominee_name,
      nominee_relation_ship,
      nominee_age,
      nominee_address,
      nominee_state,
      nominee_city,
      nominee_pincode,
      customer_bank_name,
      customer_account_no,
      customer_branch_name,
      customer_ifsc_code,
      customer_pan_no,
      customer_passport_no,
      income_details_occupation,
      income_details_professional_details,
      income_details_monthly_income,
    })
      .then(res => {
        window.alert(res.response?.response_message)
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
        })
      })
  }

  useEffect(() => {
    ;(async () => {
      const newstateList = await __getStateData()
      console.log(newstateList)
      updateState({ stateList: newstateList })
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      const newList = await __getOccupationData()
      console.log(newList)
      updateState({ occupationList: newList })
    })()
  }, [])

  const handleGetMemberDetails = () => {
    try {
      updateState({
        isLoading: true,
      })
      __postApiData(`/g_customer_details`, { member_id: member_id })
        .then(res => {
          if (res.response.response_code == "200") {
            updateState({
              ...res.data,
            })
          } else {
            window.alert(res.response?.response_message)
          }
          return updateState({
            isLoading: false,
          })
        })
        .catch(error => {
          console.error(error)
          return updateState({
            isLoading: false,
          })
        })
    } catch (error) {}
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className=" rounded-4">
                <CardBody className="p-5">
                  <Row className="">
                    <Col md={"3"} className="">
                      <div className="d-flex  align-items-center">
                        Enter Member ID
                      </div>
                    </Col>

                    <Col md={"4"} className="">
                      <div className="">
                        <Input
                          type="text"
                          placeholder="ID no..."
                          className=" border-0 bg-light rounded-0 p-3"
                          name="member_id"
                          value={member_id}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={"2"} className="">
                      <button
                        className="btn btn-success "
                        onClick={handleGetMemberDetails}
                      >
                        Show Details
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col md={"3"} className="">
                      <div className="d-flex  align-items-center">
                        Customer Name
                      </div>
                    </Col>
                    <Col md={"2"} className="">
                      <div className="">
                        <select
                          className="form-select border-0 bg-light rounded-0 p-3"
                          name="name_type"
                          value={name_type}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        >
                          <option value="Mr.">Mr.</option>
                          <option value="Miss">Miss</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={"7"} className="">
                      <div className="">
                        <Input
                          type="text"
                          placeholder="Type here..."
                          className=" border-0 bg-light rounded-0 p-3"
                          name="name"
                          value={name}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col md={"3"} className="">
                      <div className="d-flex  align-items-center">Care of*</div>
                    </Col>
                    <Col md={"2"} className="">
                      <div className="">
                        <select
                          className="form-select border-0 bg-light rounded-0 p-3"
                          name="care_of_type"
                          value={care_of_type}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        >
                          <option value="S/o">S/o</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={"7"} className="">
                      <div className="">
                        <Input
                          type="text"
                          className=" border-0 bg-light rounded-0 p-3"
                          placeholder="Type here..."
                          name="care_of_name"
                          value={care_of_name}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col md={"12"} className="">
                      <Label className="form-label">
                        Correspondence Address
                      </Label>

                      <div className="">
                        <Input
                          type="textarea"
                          placeholder="Type here..."
                          className=" border-0 bg-light rounded-0 p-3"
                          name="correspondence_address"
                          value={correspondence_address}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Row className="mt-2">
                      <Col md={"4"} className="">
                        <Label className="form-label"> State</Label>
                        <div className="">
                          <select
                            className="form-select border-0 bg-light rounded-0 p-3"
                            name="c_state"
                            value={c_state}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          >
                            <option value="">choose</option>
                            {stateList?.map(item => (
                              <option value={item.name} key={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md={"4"} className="">
                        <Label className="form-label"> City</Label>
                        <div className="">
                          <Input
                            type="text"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="c_city"
                            value={c_city}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={"4"} className="">
                        <Label className="form-label"> Pin Code</Label>
                        <div className="">
                          <Input
                            type="number"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="c_pin"
                            value={c_pin}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Row>
                  <Row className="mb-3">
                    <Col md={"12"} className="">
                      <Label className="form-label">Parmanent Address</Label>

                      <div className="">
                        <Input
                          type="textarea"
                          placeholder="Type here..."
                          className=" border-0 bg-light rounded-0 p-3"
                          name="permanent_address"
                          value={permanent_address}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Row className="mt-2">
                      <Col md={"4"} className="">
                        <Label className="form-label"> State</Label>
                        <div className="">
                          <select
                            className="form-select border-0 bg-light rounded-0 p-3"
                            name="p_state"
                            value={p_state}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          >
                            <option value="">choose</option>
                            {stateList?.map(item => (
                              <option value={item.name} key={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md={"4"} className="">
                        <Label className="form-label"> City</Label>
                        <div className="">
                          <Input
                            type="text"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="p_city"
                            value={p_city}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={"4"} className="">
                        <Label className="form-label"> Pin Code</Label>
                        <div className="">
                          <Input
                            type="number"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="p_pin"
                            value={p_pin}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Row>
                      <Col md={"4"} className="">
                        <Label className="form-label">Date of Birth</Label>

                        <div className="">
                          <Input
                            type="date"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="dob"
                            value={dob}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={"4"} className="">
                        <Label className="form-label"> Age</Label>
                        <div className="">
                          <Input
                            type="number"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="age"
                            value={age}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={"4"} className="">
                        <Label className="form-label"> Gender</Label>
                        <div className="">
                          <select
                            className="form-select border-0 bg-light rounded-0 p-3"
                            name="gender"
                            value={gender}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          >
                            <option value="">choose</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={"4"} className="">
                        <Label className="form-label"> Marital Status </Label>
                        <div className="">
                          <select
                            className="form-select border-0 bg-light rounded-0 p-3"
                            name="marital_status"
                            value={marital_status}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          >
                            <option value="">choose</option>
                            <option value="Married">Married</option>
                            <option value="Unmarried">Unmarried</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={"4"} className="">
                        <Label className="form-label"> Nationality</Label>
                        <div className="">
                          <Input
                            type="text"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="nationality"
                            value={nationality}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={"4"} className="">
                        <Label className="form-label"> Mobile No</Label>
                        <div className="">
                          <Input
                            type="number"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="mobile_no"
                            value={mobile_no}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Row>
                  <Row className="mb-3">
                    <Col md={"12"} className="">
                      <Label className="form-label">Email Id</Label>

                      <div className="">
                        <Input
                          type="text"
                          placeholder="Type here..."
                          className=" border-0 bg-light rounded-0 p-3"
                          name="email"
                          value={email}
                          onChange={e => {
                            updateState({ [e.target.name]: e.target.value })
                          }}
                        />
                      </div>
                    </Col>
                    <Row className="mt-2">
                      <Col md={"4"} className="">
                        <Label className="form-label"> Is Senior Citizen</Label>
                        <div className="">
                          <select
                            className="form-select border-0 bg-light rounded-0 p-3"
                            name="is_senior_citizen"
                            value={is_senior_citizen}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          >
                            <option value={null}>choose</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3 text-uppercase">
                    Income Details{" "}
                  </CardTitle>
                  <Row className="mb-3">
                    <Row>
                      <Col md={"4"} className="">
                        <Label className="form-label"> Occupation</Label>
                        <div className="">
                          <select
                            className="form-select border-0 bg-light rounded-0 p-3"
                            name="income_details_occupation"
                            value={income_details_occupation}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          >
                            <option value={""}>choose</option>
                            {occupationList?.map(item => (
                              <option value={item.name} key={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>

                      <Col md={"4"} className="">
                        <Label className="form-label">
                          Monthly Total Family Income
                        </Label>
                        <div className="">
                          <Input
                            type="number"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="income_details_monthly_income"
                            value={income_details_monthly_income}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={"12"} className="mt-3">
                        <Label className="form-label">
                          Professional Details
                        </Label>
                        <div className="">
                          <Input
                            type="textarea"
                            placeholder="Type here..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="income_details_professional_details"
                            value={income_details_professional_details}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="5">
              <Card>
                <CardBody>
                  <CardTitle>Nominee Details</CardTitle>
                  <Col md={"12"} className="">
                    <Label className="form-label"> Nominee Name</Label>
                    <div className="mb-3">
                      <Input
                        name="nominee_name"
                        value={nominee_name}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={"12"} className="">
                    <Label className="form-label"> Relation Ship</Label>
                    <div className="mb-3">
                      <Input
                        name="nominee_relation_ship"
                        value={nominee_relation_ship}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={"12"} className="">
                    <Label className="form-label"> Age</Label>
                    <div className="mb-3">
                      <Input
                        name="nominee_age"
                        value={nominee_age}
                        type="number"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={"12"} className="">
                    <Label className="form-label"> Address</Label>
                    <div className="mb-3">
                      <Input
                        name="nominee_address"
                        value={nominee_address}
                        type="textarea"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={"12"} className="">
                    <Label className="form-label"> State</Label>
                    <div className="mb-3">
                      <select
                        className="form-select border-0 bg-light rounded-0 p-3"
                        name="nominee_state"
                        value={nominee_state}
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      >
                        <option value="">choose</option>
                        {stateList?.map(item => (
                          <option value={item.name} key={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={"12"} className="">
                    <Label className="form-label"> City</Label>
                    <div className="mb-3">
                      <Input
                        name="nominee_city"
                        value={nominee_city}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={"12"} className="">
                    <Label className="form-label"> Pincode</Label>
                    <div className="mb-3">
                      <Input
                        name="nominee_pincode"
                        value={nominee_pincode}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Custmore Bank Details</CardTitle>
                  <Col md={"12"} className="">
                    <Label className="form-label"> Bank Name</Label>
                    <div className="mb-3">
                      <Input
                        name="customer_bank_name"
                        value={customer_bank_name}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={"12"} className="">
                    <Label className="form-label"> Account no</Label>
                    <div className="mb-3">
                      <Input
                        name="customer_account_no"
                        value={customer_account_no}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={"12"} className="">
                    <Label className="form-label"> Bank Branch</Label>
                    <div className="mb-3">
                      <Input
                        name="customer_branch_name"
                        value={customer_branch_name}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={"12"} className="">
                    <Label className="form-label"> IFSC Code</Label>
                    <div className="mb-3">
                      <Input
                        name="customer_ifsc_code"
                        value={customer_ifsc_code}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={"12"} className="">
                    <Label className="form-label"> Passpord</Label>
                    <div className="mb-3">
                      <Input
                        name="customer_passport_no"
                        value={customer_passport_no}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={"12"} className="">
                    <Label className="form-label"> Pan Number</Label>
                    <div className="mb-3">
                      <Input
                        name="customer_pan_no"
                        value={customer_pan_no}
                        type="text"
                        placeholder="Type here..."
                        className=" border-0 bg-light rounded-0 p-3"
                        onChange={e => {
                          updateState({ [e.target.name]: e.target.value })
                        }}
                      />
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <button
            className={"btn btn-success float-end px-5"}
            onClick={handleCreateMember}
          >
            Save
          </button>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditMember)
