import React, { useEffect } from "react"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import logo from "../../assets/images/logo.svg"
const Notification = ({ mesage, type, closeButton }) => {
  useEffect(() => {
    setTimeout(() => {
      closeButton({
        alertMessage: "",
        messageType: "",
      })
    }, 2000)
  }, [mesage])

  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1000000005" }}
      >
        <Toast isOpen={true} className={`bg-${type}`}>
          <ToastHeader
            toggle={() => {
              closeButton({
                alertMessage: "",
                messageType: "",
              })
            }}
          >
            <img src={logo} alt="" className="me-2" height="18" />
            Notification
          </ToastHeader>
          <ToastBody>{mesage}</ToastBody>
        </Toast>
      </div>
    </>
  )
}

export default Notification
