import Loader from "components/Common/Loader"
import Notification from "components/Common/Notification"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import { __formatDate2 } from "constants/constantfun"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const EditDetails = ({ edit, closeButton, isEdit, completeFun }) => {
  const [state, setState] = useState({
    loadingProgress: 10,
    isLoading: false,
    alertMessage: "",
    messageType: "",
  })

  const {
    loadingProgress,
    isLoading,
    alertMessage,
    messageType,
    name,
    email,
    company_limit,
    team_limit,
    _id,
    password,
    isActive,
    plan_start,
    plan_end,
  } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  useEffect(() => {
    edit && updateState({ ...edit })
  }, [edit])

  const __handleUpdateLead = () => {
    updateState({ isLoading: true, loadingProgress: 10, alertMessage: "" })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)

    __putApiData(`/u_owner_details`, {
      _id,
      name,
      email,
      company_limit,
      team_limit,
      password,
      isActive,
      plan_start,
      plan_end,
    })
      .then(res => {
        updateState({
          loadingProgress: 100,
        })
        if (res.response.response_code === "200") {
          completeFun()
          setTimeout(() => {
            updateState({
              alertMessage: res.response.response_message,
              messageType: "success",
              isLoading: false,
            })
          }, 1000)
        } else {
          setTimeout(() => {
            updateState({
              alertMessage: res.response.response_message,
              messageType: "danger",
              isLoading: false,
            })
          }, 1000)
        }
      })
      .catch(error => {
        console.error(error)
        updateState({
          isLoading: false,
          alertMessage: "Update failed!",
          messageType: "danger",
        })
      })
  }

  return (
    <>
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader title={""} progress={loadingProgress} />}
      <Modal isOpen={isEdit} centered size="xl">
        <ModalHeader toggle={closeButton}>Edit Client</ModalHeader>
        <ModalBody className="rounded-0 p-5">
          {/* <div className="form-check form-switch form-switch-md mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizemd"
            />
          </div> */}
          <Row>
            <Col
              xl={8}
              lg={12}
              md={12}
              sm={12}
              className="border-end border-1 pe-4"
            >
              <div className="mb-3 col-md-6">
                <Label className="form-label"> Name:</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=""
                  className=" border-0 bg-light rounded-0 p-3"
                  value={name}
                  onChange={e => {
                    updateState({ name: e.target.value })
                  }}
                />
              </div>

              <Row>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">Email:</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    value={email}
                    onChange={e => {
                      updateState({ email: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">Password:</Label>
                  <Input
                    name="name"
                    type="password"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    value={password}
                    onChange={e => {
                      updateState({ password: e.target.value })
                    }}
                  />
                </div>
              </Row>

              <Row className="border-top border-1 pt-3">
                <div className="mb-3 col-md-6">
                  <Label className="form-label">Company Limit</Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder=""
                    className=" border-0 bg-light rounded-0 p-3"
                    value={company_limit}
                    onChange={e => {
                      updateState({ company_limit: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">Team Limit</Label>
                  <Input
                    name="name"
                    type="number"
                    placeholder=" "
                    className=" border-0 bg-light rounded-0 p-3"
                    value={team_limit}
                    onChange={e => {
                      updateState({ team_limit: e.target.value })
                    }}
                  />
                </div>
              </Row>
            </Col>
            <Col xl={"4"} md="12">
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Plan Start</Label>
                <Input
                  name="name"
                  type="date"
                  placeholder=" "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={__formatDate2(plan_start)}
                  onChange={e => {
                    updateState({ plan_start: e.target.value })
                  }}
                />
              </div>
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Plan End</Label>
                <Input
                  name="name"
                  type="date"
                  placeholder="  "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={__formatDate2(plan_end)}
                  onChange={e => {
                    updateState({ plan_end: e.target.value })
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex gap-2 justify-content-end border-top border-1 pt-3 mt-5">
            <Button
              className="btn btn-outline-primary bg-white text-primary "
              onClick={closeButton}
            >
              Cancel
            </Button>
            <Button
              className="btn bg-primary border-primary "
              onClick={__handleUpdateLead}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditDetails
