import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"

const EditBox = ({ isAdd, close, complete, editData }) => {
  const [state, setState] = useState({
    isLoading: false,
    name: "",
    isActive: true,
  })
  const { name, isLoading, isActive } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleAdd = search => {
    if (!name) {
      return window.alert("State Name is required!")
    }
    __putApiData(`/u_state/${state?._id}`, {
      ...state,
    })
      .then(res => {
        window.alert(res.response.response_message)
        complete()
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        window.alert("failed")
        return updateState({
          isLoading: false,
        })
      })
  }
  useEffect(() => {
    updateState({ ...editData })
  }, [editData])

  return <React.Fragment>{addPlan()}</React.Fragment>

  function addPlan(params) {
    return (
      <Modal isOpen={isAdd} centered size="xl">
        <ModalHeader toggle={close}></ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <Row>
            <Col md={6} className="border-end border-1 pe-4">
              <div className="mb-3 col-md-12">
                <Label className="form-label"> State Name :</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=""
                  className=" border-0 bg-light rounded-0 p-3"
                  value={name}
                  onChange={e => {
                    updateState({ name: e.target.value })
                  }}
                />
              </div>
            </Col>
            <Col md={6} className="border-end border-1 pe-4">
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Status</Label>
                <select
                  className="form-select border-0 bg-light rounded-0 p-3"
                  value={isActive}
                  onChange={e => {
                    updateState({ isActive: e.target.value })
                  }}
                >
                  <option value={true}>Active</option>
                  <option value={false}>In-Acitve</option>
                </select>
              </div>
            </Col>

            <div className="d-flex gap-2 justify-content-end border-top border-1 pt-3 mt-5">
              <Button
                onClick={close}
                className="btn btn-outline-primary bg-white text-primary "
              >
                Cancel
              </Button>
              <Button
                className="btn bg-primary border-primary "
                onClick={__handleAdd}
              >
                Save
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default withTranslation()(EditBox)
