import React, { useContext, useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import {
  __deleteApiData,
  __getApiData,
  __postApiData,
  __putApiData,
} from "constants/apis"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import { Link } from "react-router-dom"
import EditDetails from "./EditDetails"
import DeleteModal from "components/Common/DeleteModal"

const ApproveMember = props => {
  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isEdit: false,
    edit: null,
    isDelete: false,
    delete_id: null,
  })
  const { leads, isLoading, isEdit, edit, isDelete, delete_id } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },

      {
        Header: <div className="text-center"> Customer-Id</div>,
        accessor: "customer_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center"> Customer Name</div>,
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center">CareOf</div>,
        accessor: "care_of_name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> DOB</div>,
        accessor: "dob",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Mobile No.</div>,
        accessor: "mobile_no",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Address</div>,
        accessor: "correspondence_address",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center">City</div>,
        accessor: "c_city",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center">PAN NO.</div>,
        accessor: "customer_pan_no",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center">Action</div>,
        disableFilters: true,
        accessor: "_id",
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <span
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      __handleApproveLeads(cellProps.value)
                    }}
                  >
                    approve
                  </span>
                </li>
                <li>
                  <span
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      updateState({
                        isDelete: true,
                        delete_id: cellProps.value,
                      })
                    }}
                  >
                    <i className="mdi mdi-delete-outline"></i>
                  </span>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )
  const __handleGetUsers = search => {
    __postApiData(`/g_approvel_list`)
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data
              .map((item, i) => ({ ...item, sr_no: i + 1 }))
              .reverse(),
            isLoading: false,
            isAsyncLoading: false,
          })
        }
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true })
    __handleGetUsers("")
  }, [])

  const __handleApproveLeads = id => {
    __putApiData(`/a_customer/${id}`)
      .then(res => {
        if (res.response.response_code === "200") {
          __handleGetUsers()
          return updateState({ isDelete: false })
        }

        window.alert(res.response.response_message)
        return updateState({ isLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false })
      })
  }
  const __handleDeleteLeads = () => {
    __deleteApiData(`/d_approvel_customer/${delete_id}`)
      .then(res => {
        if (res.response.response_code === "200") {
          __handleGetUsers()
          return updateState({ isDelete: false })
        }

        window.alert(res.response.response_message)
        return updateState({ isLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <DeleteModal
            show={isDelete}
            onDeleteClick={__handleDeleteLeads}
            onCloseClick={() =>
              updateState({ isDelete: false, delete_id: null })
            }
          />
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={30}
                classNameHeader="dark"
                isGlobalFilter
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No contact Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      {editModel()}
    </React.Fragment>
  )
  function editModel(params) {
    return (
      <>
        <EditDetails
          isEdit={isEdit}
          edit={edit}
          closeButton={() => updateState({ isEdit: false })}
          completeFun={() => {
            updateState({ isEdit: false })
            __handleGetUsers()
          }}
        />
      </>
    )
  }
}

export default withTranslation()(ApproveMember)
