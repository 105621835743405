import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"

const EditBox = ({ isAdd, close, complete, editData }) => {
  const [state, setState] = useState({
    isLoading: false,
    total_share: 0,
    isActive: true,
  })
  const { total_share, isLoading } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleAdd = search => {
    if (!total_share) {
      return window.alert("Enter Share quantity for withdow")
    }
    if (total_share > editData.total_share) {
      return window.alert(
        "Please enter a valid Share quantity, You have only " +
          editData.total_share +
          " shares"
      )
    }
    __postApiData(`/w_share`, {
      share_id: editData._id,
      total_share: total_share,
    })
      .then(res => {
        window.alert(res.response.response_message)
        complete()
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        window.alert("failed")
        return updateState({
          isLoading: false,
        })
      })
  }

  return <React.Fragment>{addPlan()}</React.Fragment>

  function addPlan(params) {
    return (
      <Modal isOpen={isAdd} centered size="xl">
        <ModalHeader toggle={close}></ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <Row>
            <Col md={6} className="border-end border-1 pe-4">
              <div className="mb-3 col-md-12">
                <Label className="form-label"> Total Share To Withdrow :</Label>
                <Input
                  name="name"
                  type="number"
                  placeholder=""
                  className=" border-0 bg-light rounded-0 p-3"
                  value={total_share}
                  onChange={e => {
                    updateState({ total_share: e.target.value })
                  }}
                />
              </div>
            </Col>

            <div className="d-flex gap-2 justify-content-end border-top border-1 pt-3 mt-5">
              <Button
                onClick={close}
                className="btn btn-outline-primary bg-white text-primary "
              >
                Cancel
              </Button>
              <Button
                className="btn bg-primary border-primary "
                onClick={__handleAdd}
              >
                Withdrow
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default withTranslation()(EditBox)
