import React, { useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"

const AddBox = ({ isAdd, close, complete }) => {
  const [state, setState] = useState({
    isLoading: false,
    name: "",
  })
  const { name, isLoading } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleAdd = search => {
    if (!name) {
      return window.alert("State Name is required!")
    }
    __postApiData(`/c_state`, {
      ...state,
    })
      .then(res => {
        window.alert(res.response.response_message)
        complete()
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        window.alert("failed")
        return updateState({
          isLoading: false,
        })
      })
  }

  return <React.Fragment>{addPlan()}</React.Fragment>

  function addPlan(params) {
    return (
      <Modal isOpen={isAdd} centered size="xl">
        <ModalHeader toggle={close}></ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <Row>
            <Col
              xl={8}
              lg={12}
              md={12}
              sm={12}
              className="border-end border-1 pe-4"
            >
              <div className="mb-3 col-md-12">
                <Label className="form-label"> State Name :</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder=""
                  className=" border-0 bg-light rounded-0 p-3"
                  value={name}
                  onChange={e => {
                    updateState({ name: e.target.value })
                  }}
                />
              </div>
            </Col>
            <div className="d-flex gap-2 justify-content-end border-top border-1 pt-3 mt-5">
              <Button
                onClick={close}
                className="btn btn-outline-primary bg-white text-primary "
              >
                Cancel
              </Button>
              <Button
                className="btn bg-primary border-primary "
                onClick={__handleAdd}
              >
                Save
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default withTranslation()(AddBox)
