import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import dataContext from "context/data/dataContext"

const NotificationDropdown = props => {
  const { newNotification } = useContext(dataContext)

  const [menu, setMenu] = useState(false)

  const [state, setstate] = useState({
    count: 0,
    notificationlist: [],
  })
  const { count, notificationlist } = state
  const UpdateState = data => setstate(prv => ({ ...prv, ...data }))

  React.useEffect(() => {
    if (newNotification.length > 0) {
      UpdateState({
        count: count + newNotification.length,
        notificationlist: [...newNotification, ...notificationlist],
      })
    }
  }, [newNotification])
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          menu && UpdateState({ count: 0 })
          setMenu(!menu)
        }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={`bx bx-bell ${count != 0 && "bx-tada"}`} />
          {count != 0 && (
            <span className="badge bg-danger rounded-pill">{count}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3 border-bottom border-1">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small">
                  {" "}
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notificationlist.map((item, i) => (
              <Link key={i} to="" className="text-reset notification-item  ">
                <div className="d-flex  border-bottom border-1 p-2 px-4">
                  {item?.other?.buyer_image && (
                    <img
                      src={item?.other?.buyer_image}
                      className="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                  )}
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{item?.other?.buyer_name}</h6>
                    <div className="font-size-12 text-muted d-flex justify-content-between m-0 p-0">
                      <p className="mb-1">{props.t(item?.title) + "."}</p>
                      <p className="mb-0">₹ {item?.other?.total_price}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
            {notificationlist.length === 0 && (
              <p className="text-muted text-center mt-5">
                {" "}
                No New Notification
              </p>
            )}
          </SimpleBar>
          {/* <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
