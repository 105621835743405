import { __getApiData, __postApiData } from "./apis"

export const __getStateData = async () => {
  return __postApiData(`/g_state_list`)
    .then(res => {
      if (res.response.response_code == "200") {
        return res.data
      }
      return []
    })
    .catch(error => {
      console.error(error)
      return []
    })
}
export const __getOccupationData = async () => {
  return __postApiData(`/g_occupation_list`)
    .then(res => {
      if (res.response.response_code == "200") {
        return res.data
      }
      return []
    })
    .catch(error => {
      console.error(error)
      return []
    })
}
export const __getCompanyAccountData = async () => {
  return __postApiData(`/g_company_bank_list`)
    .then(res => {
      if (res.response.response_code == "200") {
        return res.data
      }
      return []
    })
    .catch(error => {
      console.error(error)
      return []
    })
}
