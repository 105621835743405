import React, { useContext, useEffect, useState } from "react"
import { Card, CardBody, Container, Row, Col } from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"
const Dashboard = props => {
  const [state, setState] = useState({
    isLoaded: false,
    client: 0,
    loan_amount: 0,
    total_share: 0,
    total_amount: 0,
  })
  const { isLoaded, client, loan_amount, total_share, total_amount } = state

  const updateState = data => setState(prv => ({ ...prv, ...data }))

  const __handleGetDahboard = () => {
    updateState({ isLoaded: true })

    __postApiData(`/g_dashboard`)
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({ ...res.data, isLoaded: false })
        } else {
          updateState({ isLoaded: true })
        }
      })
      .catch(error => {
        console.error(error)
        updateState({ isLoaded: true })
      })
  }

  useEffect(() => {
    __handleGetDahboard()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm="3">
              <Card className="mini-stats-wid rounded-4 p-lg-2">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3 align-self-center">
                      <i className="bx bx-copy-alt h2 text-warning mb-0" />
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-2">Total Client</p>
                      {isLoaded ? (
                        <i className="bx bx-loader bx-spin text-dark align-middle   font-size-18"></i>
                      ) : (
                        <h5 className="mb-0">{client || 0}</h5>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card className="mini-stats-wid rounded-4 p-lg-2">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3 align-self-center">
                      <i className="bx bx-copy-alt h2 text-primary mb-0" />
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-2">Total Loan Amount</p>

                      {isLoaded ? (
                        <i className="bx bx-loader bx-spin text-dark align-middle  font-size-18 "></i>
                      ) : (
                        <h5 className="mb-0">₹ {loan_amount || 0}</h5>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card className="mini-stats-wid rounded-4 p-lg-2">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3 align-self-center">
                      <i className="bx bx-money h2 text-info mb-0" />
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-2">Total Share</p>
                      {isLoaded ? (
                        <i className="bx bx-loader bx-spin text-dark align-middle  font-size-18 "></i>
                      ) : (
                        <h5 className="mb-0">{total_share || 0}</h5>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card className="mini-stats-wid rounded-4 p-lg-2">
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3 align-self-center">
                      <i className="bx bx-money h2 text-success mb-0" />
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-2"> Total Share Amount</p>
                      {isLoaded ? (
                        <i className="bx bx-loader bx-spin text-dark align-middle  font-size-18 "></i>
                      ) : (
                        <h5 className="mb-0">₹ {total_amount || 0}</h5>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
