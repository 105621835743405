import React, { useContext, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Input,
  Col,
  CardTitle,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import { __formatDate } from "constants/constantfun"
import { useNavigate } from "react-router-dom"
const NewLoan = props => {
  const navigation = useNavigate()
  const [state, setState] = useState({
    isLoading: false,
    member_details: {},
    guarantor_details: {},
    share_details: "",
    member_id: "",
    guarantor_id: "",
    guarantor_relation: "",
    loan_list: [],
    duration_months: "",
    issue_date: "",
    installment_amount: "",
    process_fee: "",
    first_installment_date: "",
    security_money: "",
    installment_type: "",
    period_type: "",
    intrest_rate: "",
    loan_amount: "",
    maturity_date: "",
    total_intrest_amount: "",
    tax_amount: "",
    loan_remark: "",
    is_deductible: false,
    loan_id: "",
    qty: {},
    rate_type: "Flat",
  })
  const {
    member_id,
    customer_id,
    member_details,
    guarantor_id,
    guarantor_details,
    guarantor_relation,
    loan_list,
    duration_months,
    issue_date,
    installment_amount,
    process_fee,
    first_installment_date,
    security_money,
    installment_type,
    period_type,
    intrest_rate,
    loan_amount,
    maturity_date,
    total_intrest_amount,
    tax_amount,
    loan_remark,
    is_deductible,
    loan_id,
    qty,
    rate_type,
  } = state
  const updateState = data => setState(prv => ({ ...prv, ...data }))

  const handleGetMemberDetails = () => {
    updateState({
      isLoading: true,
    })
    __postApiData(`/c_customer_details`, { customer_id: member_id })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            isLoading: false,
            member_details: { ...res.data },
          })
        }
        window.alert(res.response?.response_message)
        return updateState({
          isLoading: false,
          member_details: {},
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
          member_details: {},
        })
      })
  }
  const handleGetGuarantorDetails = () => {
    updateState({
      isLoading: true,
    })
    __postApiData(`/c_customer_details`, { customer_id: guarantor_id })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            isLoading: false,
            guarantor_details: { ...res.data },
          })
        }
        window.alert(res.response?.response_message)
        return updateState({
          isLoading: false,
          guarantor_details: {},
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
          guarantor_details: {},
        })
      })
  }
  const handleCreateNewLoan = () => {
    updateState({
      isLoading: true,
    })
    __postApiData(`/a_loan`, {
      guarantor_id: guarantor_details._id,
      member_id: member_details._id,
      guarantor_relation,
      loan_id,
      duration_months,
      issue_date,
      installment_amount,
      process_fee,
      first_installment_date,
      security_money,
      installment_type,
      period_type,
      intrest_rate,
      loan_amount,
      maturity_date,
      total_intrest_amount,
      tax_amount,
      loan_remark,
      is_deductible,
      rate_type,
    })
      .then(res => {
        window.alert(res.response?.response_message)
        if (res.response?.response_code == "200") {
          navigation("/approve-loan")
          return updateState({
            isLoading: false,
            member_details: {},
            guarantor_details: {},
            share_details: "",
            member_id: "",
            guarantor_id: "",
            guarantor_relation: "",
            loan_list: [],
            duration_months: "",
            issue_date: "",
            installment_amount: "",
            process_fee: "",
            first_installment_date: "",
            security_money: "",
            installment_type: "",
            period_type: "",
            intrest_rate: "",
            loan_amount: "",
            maturity_date: "",
            total_intrest_amount: "",
            tax_amount: "",
            loan_remark: "",
            is_deductible: false,
            loan_id: "",
            qty: {},
            rate_type: "Flat",
          })
        }
        return updateState({
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
        })
      })
  }

  const handleGetloanListDetails = () => {
    updateState({
      isLoading: true,
    })
    __postApiData(`/g_loan_list`)
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            isLoading: false,
            loan_list: res.data,
          })
        }
        window.alert(res.response?.response_message)
        return updateState({
          isLoading: false,
          loan_list: [],
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
          loan_list: [],
        })
      })
  }
  useEffect(() => {
    handleGetloanListDetails()
  }, [])

  const updateLoanIntrestAndInstallment = () => {
    const TotalIntrest = calculateTotalInterest(
      Number(loan_amount),
      Number(duration_months),
      Number(intrest_rate),
      rate_type
    )
    console.log(TotalIntrest)

    updateState({
      total_intrest_amount: Number(TotalIntrest),
      installment_amount: Math.round(
        (Number(TotalIntrest) + Number(loan_amount)) / Number(duration_months)
      ),
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className=" rounded-4">
                <CardBody className="p-5">
                  <Col md={6}>
                    <Row className="mb-3">
                      <Col md={"5"} className="">
                        <div className="d-flex  align-items-center">
                          Enter Member ID
                        </div>
                      </Col>

                      <Col md={"7"} className="">
                        <div className="">
                          <Input
                            type="text"
                            placeholder="ID no..."
                            className=" border-0 bg-light rounded-0 p-3"
                            name="member_id"
                            value={member_id}
                            onChange={e => {
                              updateState({ [e.target.name]: e.target.value })
                            }}
                            onBlur={handleGetMemberDetails}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <CardTitle>Persional Details</CardTitle>
                  <Row className="mb-3 mt-4">
                    <Col md={6}>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Customer Name
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={member_details?.name || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            PAN No.
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={member_details?.customer_pan_no || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Mobile No
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={member_details?.mobile_no || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">City</div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={member_details?.c_city || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className=" rounded-4 ">
                <CardBody className="p-5">
                  <Row>
                    <Col>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Select Loan
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <select
                              className="form-select border-0 bg-light rounded-0 p-3"
                              name="loan_id"
                              onChange={e => {
                                const newData = loan_list.find(
                                  item => item._id === e.target.value
                                )
                                console.log(newData)
                                if (!newData) {
                                  return
                                }
                                updateState({
                                  [e.target.name]: e.target.value,
                                  process_fee: newData.processing_fee,
                                  period_type: newData.period_type,
                                  intrest_rate: newData.rate_of_intrest,
                                  installment_type: newData.installment_type,
                                  tax_amount: newData.tax_amount,
                                  duration_months: newData.min_period,
                                  qty: {
                                    min_amount: newData.min_amount,
                                    max_amount: newData.max_amount,
                                    min_period: newData.min_period,
                                    max_period: newData.max_period,
                                  },
                                })
                              }}
                            >
                              <option value="">choose</option>

                              {loan_list.map(item => (
                                <option value={item._id} key={item._id}>
                                  {item?.loan_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Select Rate Type
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <select
                              className="form-select border-0 bg-light rounded-0 p-3"
                              name="rate_type"
                              value={rate_type}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            >
                              <option value="Reducing">
                                Reducing Balance Rate
                              </option>
                              <option value="Flat">Flat Rate</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Duration In Months
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="duration_months"
                              value={duration_months}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                              onBlur={() => {
                                if (
                                  qty.min_period > duration_months ||
                                  duration_months > qty.max_period
                                ) {
                                  updateState({
                                    duration_months: qty.min_period,
                                  })
                                  window.alert(
                                    `invalid  loan duration. It must be in ${qty.min_period} - ${qty.max_period} `
                                  )
                                } else {
                                  updateLoanIntrestAndInstallment()
                                }
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Issue Date
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="date"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="issue_date"
                              value={issue_date}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Installment Amount
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="installment_amount"
                              value={installment_amount}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Processing Fees
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="process_fee"
                              value={process_fee}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            First Installment Date
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="date"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="first_installment_date"
                              value={first_installment_date}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Security Money
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="security_money"
                              value={security_money}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Duration Type
                          </div>
                        </Col>

                        <Col md={"3"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="total_share"
                              value={period_type}
                            />
                          </div>
                        </Col>
                        <Col md={"4"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="total_share"
                              value={installment_type}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Interest Rate
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="intrest_rate"
                              value={intrest_rate}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Loan Amount
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="loan_amount"
                              value={loan_amount}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                              onBlur={() => {
                                if (
                                  qty.min_amount > loan_amount ||
                                  loan_amount > qty.max_amount
                                ) {
                                  updateState({
                                    loan_amount: 0,
                                    total_intrest_amount: 0,
                                    installment_amount: 0,
                                  })
                                  window.alert(
                                    `invalid  loan amount.it must be in ${qty.min_amount} - ${qty.max_amount} `
                                  )
                                } else {
                                  updateLoanIntrestAndInstallment()
                                }
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Maturity Date:
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="date"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="maturity_date"
                              value={maturity_date}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Total intesest Amount
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="total_intrest_amount"
                              value={total_intrest_amount}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Tax Amount
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="number"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="tax_amount"
                              value={tax_amount}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Loan Remarks
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="textarea"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="loan_remark"
                              value={loan_remark}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Deduct Processing Fees and Security Money from Loan
                            Amount
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <select
                              className="form-select border-0 bg-light rounded-0 p-3"
                              name="is_deductible"
                              value={is_deductible}
                              onChange={e => {
                                updateState({ [e.target.name]: e.target.value })
                              }}
                            >
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className=" rounded-4">
                <CardBody className="p-5">
                  <CardTitle>Guarantor Details</CardTitle>

                  <Row className="mb-3 mt-4">
                    <Col md={6}>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Enter Member ID
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={guarantor_id || ""}
                              onChange={e => {
                                updateState({ guarantor_id: e.target.value })
                              }}
                              onBlur={handleGetGuarantorDetails}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">Name</div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={guarantor_details?.name || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">Age</div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={guarantor_details?.age || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Profession
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={
                                guarantor_details?.income_details_occupation ||
                                ""
                              }
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Address
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={
                                guarantor_details?.correspondence_address || ""
                              }
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Mobile No
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={guarantor_details?.mobile_no || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Relation
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={guarantor_relation || ""}
                              onChange={e => {
                                updateState({
                                  guarantor_relation: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={"5"} className="">
                          <div className="d-flex  align-items-center">
                            Year Income Rs.
                          </div>
                        </Col>

                        <Col md={"7"} className="">
                          <div className="">
                            <Input
                              type="text"
                              placeholder=""
                              className=" border-0 bg-light rounded-0 p-3"
                              name="member_id"
                              value={
                                guarantor_details?.income_details_monthly_income ||
                                ""
                              }
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>

                      <button
                        onClick={handleCreateNewLoan}
                        className="btn btn-success mt-5 float-end"
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
  function calculateTotalInterest(
    amount,
    durationMonths,
    interestRate,
    rateType
  ) {
    let totalInterest = 0
    let remainingAmount = amount

    const MonthlyInterestRate = interestRate / 100 / 12

    if (rateType === "Flat") {
      return amount * MonthlyInterestRate * durationMonths
    } else if (rateType === "Reducing") {
      for (let month = 0; month < durationMonths; month++) {
        const monthlyInterest = Math.round(
          remainingAmount * MonthlyInterestRate
        )
        totalInterest += Math.round(monthlyInterest)
        remainingAmount -= amount / durationMonths
      }
    }

    return totalInterest
  }
}

export default withTranslation()(NewLoan)
