import React from "react"
import { Progress, Toast, ToastBody, ToastHeader } from "reactstrap"
import logo from "../../assets/images/logo.svg"
const Loader = ({ progress = 10, title }) => {
  return (
    <>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1000000005" }}
      >
        <Toast isOpen={true}>
          <div className="bg-white p-2">
            <img src={logo} alt="" className="me-2" height="18" />
            Loading...
          </div>
          <ToastBody className="bg-light">
            <div className="animated-progess my-2 rounded-0">
              <Progress
                value={progress}
                color="warning"
                className="rounded"
              ></Progress>
            </div>
          </ToastBody>
        </Toast>
      </div>
    </>
  )
}

export default Loader
