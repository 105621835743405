import React, { useContext, useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import { Link } from "react-router-dom"
import EditBox from "./EditBox"

const ShareReport = props => {
  const urlParams = new URLSearchParams(window.location.search)

  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isEdit: false,
    edit: null,
  })
  const { leads, isLoading, isEdit, edit } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: "Customer ID",
        accessor: "customer_id",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Share No.",
        accessor: "share_no",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center">Total Share</div>,
        accessor: "total_share",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "0"}</div>
        },
      },
      {
        Header: <div className="text-center"> Share Amount</div>,
        accessor: "share_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">₹{cellProps.value || "0"}</div>
        },
      },
      {
        Header: <div className="text-center">Profit Interest</div>,
        accessor: "profit_interest",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || 0}%</div>
        },
      },

      {
        Header: <div className="text-center">Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },
      {
        Header: <div className="text-center">Last Payment Date</div>,
        accessor: "last_payment_date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {cellProps.value ? __formatDate(cellProps.value) : "-"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> Remark</div>,
        accessor: "remark",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Action</div>,
        accessor: "_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <span
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      __handleShareProfitPayment(cellProps.value)
                    }}
                  >
                    Pay
                  </span>
                </li>
                <li>
                  <span
                    className="btn btn-sm btn-soft-warning"
                    onClick={() => {
                      updateState({
                        isEdit: true,
                        edit: data,
                      })
                    }}
                  >
                    Withdrow
                  </span>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )
  const __handleGetUsers = search => {
    __postApiData(`/g_report`, {})
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data?.history,
            isLoading: false,
          })
        }
        return updateState({
          leads: [],
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          leads: [],
          isLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true })
    __handleGetUsers("")
  }, [])

  const __handleShareProfitPayment = id => {
    __postApiData(`/p_share_profit/${id}`)
      .then(res => {
        window.alert(res.response.response_message)
        __handleGetUsers("")
      })
      .catch(error => {
        console.error(error)
        return updateState({
          isLoading: false,
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={30}
                classNameHeader="dark"
                isGlobalFilter
                isNextPrvButtonsEnabled={leads?.length == 0 ? false : true}
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Report Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      {editPlan()}
    </React.Fragment>
  )
  function editPlan(params) {
    return (
      <>
        <EditBox
          isAdd={isEdit}
          close={() => updateState({ isEdit: false })}
          complete={() => {
            updateState({ isEdit: false })
            __handleGetUsers()
          }}
          editData={edit}
        />
      </>
    )
  }
}

export default withTranslation()(ShareReport)
