import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPassword from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import NewRegistration from "pages/NewRegistration"
import CustomersList from "pages/Customers"
import ShareMaster from "pages/ShareMaster"
import ShareReport from "pages/ShareReport"
import LoanMaster from "pages/LoanMaster"
import NewLoan from "pages/NewLoan"
import ApproveLoan from "pages/ApproveLoan"
import ApproveMember from "pages/ApproveMember"
import WalletHistory from "pages/WalletHistory"
import LoanInstallment from "pages/LoanInstallment"
import ApprovedLoan from "pages/ApprovedLoan"
import Occupation from "pages/Occupation"
import BankAccount from "pages/BankAccount"
import LoanRequest from "pages/LoanRequest"
import StateMaster from "pages/StateMaster"
import EditMember from "pages/EditMember"
import UploadDocument from "pages/UploadDocument"
import ShareWithdrowReport from "pages/ShareWithdrowReport"
import ShareProfitHistory from "pages/ShareProfitHistory"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/new-registration", component: <NewRegistration /> },
  { path: "/customer-list", component: <CustomersList /> },
  { path: "/share-master", component: <ShareMaster /> },
  { path: "/share-report", component: <ShareReport /> },
  { path: "/loan-master", component: <LoanMaster /> },
  { path: "/new-loan", component: <NewLoan /> },
  { path: "/approve-loan", component: <ApproveLoan /> },
  { path: "/approve-member", component: <ApproveMember /> },
  { path: "/wallet-history", component: <WalletHistory /> },
  { path: "/loan-installment", component: <LoanInstallment /> },
  { path: "/loan-list", component: <ApprovedLoan /> },
  { path: "/occupation", component: <Occupation /> },
  { path: "/company-account", component: <BankAccount /> },
  { path: "/loan-request", component: <LoanRequest /> },
  { path: "/state", component: <StateMaster /> },
  { path: "/edit-member", component: <EditMember /> },
  { path: "/upload-document", component: <UploadDocument /> },
  { path: "/share-withdrow-report", component: <ShareWithdrowReport /> },
  { path: "/share-profit-report", component: <ShareProfitHistory /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "*",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
]

export { authProtectedRoutes, publicRoutes }
