import React, { useContext, useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import {
  __deleteApiData,
  __getApiData,
  __postApiData,
  __putApiData,
} from "constants/apis"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import { Link } from "react-router-dom"

const LoanInstallment = props => {
  const urlParams = new URLSearchParams(window.location.search)

  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isDelete: false,
    delete_id: null,
  })
  const { leads, isLoading, isDelete, delete_id } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: "Customer ID",
        accessor: "customer_id",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center"> Installment No.</div>,
        accessor: "installment_no",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "0"}</div>
        },
      },
      {
        Header: <div className="text-center"> Installment Amount</div>,
        accessor: "installment_amount",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "0"}</div>
        },
      },
      {
        Header: <div className="text-center"> Interest Amount</div>,
        accessor: "interest",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "0"}</div>
        },
      },
      {
        Header: <div className="text-center"> Principal Amount</div>,
        accessor: "principal",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "0"}</div>
        },
      },

      {
        Header: <div className="text-center">Installment Date</div>,
        accessor: "installment_date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },
      {
        Header: <div className="text-center">Payment Status</div>,
        accessor: "is_paid",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {cellProps.value ? (
                <span className="bg-success badge bg-secondary">Paid</span>
              ) : (
                <span className="bg-warning badge bg-secondary">Panding</span>
              )}
            </div>
          )
        },
      },

      {
        Header: <div className="text-center"> Action</div>,
        accessor: "_id",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <span
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      __handlePaymentInstallment(cellProps.value)
                    }}
                  >
                    Pay
                  </span>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )
  const __handleGetUsers = search => {
    __postApiData(`/g_loan_installment/${urlParams.get("loan")}`, {})
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data?.reverse(),
            isLoading: false,
          })
        }
        return updateState({
          leads: [],
          isLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          leads: [],
          isLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true })
    __handleGetUsers("")
  }, [])
  const __handlePaymentInstallment = id => {
    __putApiData(`/u_pay_installment/${id}`)
      .then(res => {
        window.alert(res.response.response_message)

        if (res.response.response_code === "200") {
          __handleGetUsers()
          return updateState({ isDelete: false })
        }

        return updateState({ isLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={30}
                classNameHeader="dark"
                isGlobalFilter
                isNextPrvButtonsEnabled={leads?.length == 0 ? false : true}
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Report Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(LoanInstallment)
